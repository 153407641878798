import addPageMetaDataTracking from '@ri-digital/web-data-layer/dist/events/AddPageMetaDataTracking';
import getCookie from '../../helpers/getCookie/getCookie';
import pageType from '../../helpers/pageType/pageType';

export const getCookieConsentData = () => {
  const data = getCookie('cookie_consent');
  if (data) {
    const { analytics, marketing } = JSON.parse(data);
    return { analytics, marketing };
  }
  return { analytics: false, marketing: false };
};

const pageMetaData = ({ view, type, name, contentGroup }) => {
  const country = document.getElementById('country-code')?.value;
  const currency = document.getElementById('currency-code')?.value;
  const language = document.getElementById('language')?.value;
  const campaign = document.getElementById('utm_campaign')?.value;
  const source = document.getElementById('utm_source')?.value;
  const medium = document.getElementById('utm_medium')?.value;

  const newData = {
    type: pageType(type),
    view,
    countryCode: country,
    currencyCode: currency,
    locale: language,
    env: process.env.NODE_ENV,
    name,
    contentGroup,
    campaign,
    source,
    medium,
  };
  addPageMetaDataTracking(newData);
};

export default pageMetaData;
