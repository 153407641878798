import React from 'react';

const Tick = ({ ...props }) => (
  <svg
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.9307 2.06377C16.6195 6.44234 10.2329 12.7644 8.12729 14.8453C7.7367 15.2313 7.1124 15.2252 6.72362 14.8374L0.274902 8.4043L1.06913 7.61007L7.42591 13.9668L20.9307 0.462036L21.725 1.25627C21.725 1.25627 21.2411 1.74858 20.9307 2.06377Z"
      fill="black"
    />
  </svg>
);

export default Tick;
