import addToDataLayer from '../../helpers/addToDataLayer/addToDataLayer';

const myAccountJourney = () => {
  const country = document.getElementById('country-code')?.value;
  const currency = document.getElementById('currency-code')?.value;
  const language = document.getElementById('language')?.value;

  const newData = {
    context: {
      currency,
      country,
      language,
      environment: process.env.NODE_ENV,
    },
    data: [
      {
        type: 'my-account',
        view: 'my-account-sign-in',
      },
    ],
  };
  addToDataLayer('my-account', newData);
};

export default myAccountJourney;
