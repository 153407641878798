import React, { useMemo } from 'react';
import PageWrapper from '../../components/PageWrapper';
import { UnderlinedLink } from '../../components/UnderlinedLink/UnderlinedLink';
import { getMainSiteUrl } from '../../helpers/getMainSiteUrl';

export function ErrorPage() {
  const mainSiteUrl = useMemo(getMainSiteUrl, []);

  return (
    <PageWrapper heading="COMPUTER SAYS NO" dataTestId="error-page">
      <p data-testid="error-page-title">
        The page you were looking for couldn&rsquo;t be found
      </p>
      {mainSiteUrl && (
        <UnderlinedLink as="a" href={mainSiteUrl} data-testid="error-page-link">
          Go back to the homepage
        </UnderlinedLink>
      )}
    </PageWrapper>
  );
}
