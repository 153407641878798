import addToDataLayer from '../../helpers/addToDataLayer/addToDataLayer';
import pageType from '../../helpers/pageType/pageType';

const signUp = (error, typeOfPage) => {
  const type = pageType(typeOfPage);
  const data = {
    signup: {
      error: !error ? 0 : 1,
      id: `${type}-sign-up`,
      type: `Email sign up ${typeOfPage}`,
    },
  };
  return addToDataLayer('signUp', data);
};

export default signUp;
