import addToDataLayer from '../../helpers/addToDataLayer/addToDataLayer';
import pageType from '../../helpers/pageType/pageType';

const signIn = (error, typeOfPage) => {
  const type = pageType(typeOfPage);
  const data = {
    signin: {
      error: !error ? 0 : 1,
      id: `${type}-sign-in`,
      type: `Email sign in ${typeOfPage}`,
    },
  };
  return addToDataLayer('signIn', data);
};

export default signIn;
