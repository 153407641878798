import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import style from './Tabs.module.scss';
import text from '../../text';
import getCookie from '../../../analytics/helpers/getCookie/getCookie';

const cx = classNames.bind(style);
const { tabs } = text;

function Tabs({ activeTab, setActiveTab, enableTabs }) {
  const isAccount = activeTab === 'account';
  const isGuest = activeTab === 'guest';

  const guestCheckoutSavedEmail = !!sessionStorage.getItem('guest_email');
  const isPromoSaved = !!sessionStorage.getItem('fake_door_promo_code');
  const isFakeDoorEnabled = !!getCookie('qb_guest_fake_door');

  const clickHandler = (e) => {
    setActiveTab(e.target.dataset.value);
  };

  const fakeDoorOnWithTabsEnabled = isFakeDoorEnabled && enableTabs;
  const userSeenUnderConstruction = isPromoSaved || guestCheckoutSavedEmail;

  return (
    <>
      {fakeDoorOnWithTabsEnabled && !userSeenUnderConstruction ? (
        <div className={cx('tabs')} data-testid="guest-tabs">
          <div
            className={cx('tabs__box', { active: isAccount })}
            data-testid="account-tab"
          >
            <button
              type="button"
              data-value="account"
              onClick={clickHandler}
              data-item-category="General Interaction"
              data-item-name="with-an-account"
            >
              {tabs.account}
            </button>
          </div>

          <div
            className={cx('tabs__box', { active: isGuest })}
            data-testid="guest-tab"
          >
            <button
              type="button"
              data-value="guest"
              onClick={clickHandler}
              data-testid="tabs-guest-button"
              data-item-category="General Interaction"
              data-item-name="as-a-guest"
            >
              {tabs.guest}
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
}

Tabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  enableTabs: PropTypes.bool,
};

Tabs.defaultProps = {
  enableTabs: false,
};

export default Tabs;
