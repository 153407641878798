const addToDataLayer = (eventName, newData) => {
  if (window?.riDigitalData?.add) {
    window.riDigitalData.add(eventName, newData);
  } else {
    // eslint-disable-next-line no-console
    console.error(
      `Looks like you haven't initialised window.riDigitalData yet but tried to add data`,
    );
  }
};

export default addToDataLayer;
