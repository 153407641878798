import addToDataLayer from '../../helpers/addToDataLayer/addToDataLayer';

const emailLookUp = (error) => {
  const data = {
    emailLookUp: {
      error: !error ? 0 : 1,
      id: `email-lookup`,
      type: `Email lookup`,
    },
  };
  return addToDataLayer('emailLookUp', data);
};

export default emailLookUp;
