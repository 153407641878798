import { useLocation } from 'react-router-dom';
import routes from '../../../routes';
import getCookie from '../../../../analytics/helpers/getCookie/getCookie';

const usePromoCodeNotification = (
  activePages = [
    routes.login.path,
    routes.checkoutSignUp.path,
    routes.passwordValidation.path,
  ],
  promoCodeKey = 'fake_door_promo_code',
) => {
  const location = useLocation();
  const promoCode = window.sessionStorage.getItem(promoCodeKey);
  const isFakeDoorEnabled = !!getCookie('qb_guest_fake_door');

  return (
    isFakeDoorEnabled && activePages.includes(location.pathname) && promoCode
  );
};

export default usePromoCodeNotification;
