import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Tabs from '../Tabs';

import style from './PageWrapper.module.scss';
import Notification from '../Notification';
import usePromoCodeNotification from '../../helpers/hooks/usePromoCodeNotification/usePromoCodeNotification';
import Tick from '../Icons/Tick';

const cx = classNames.bind(style);

const PageWrapper = ({
  heading,
  children,
  dataTestId,
  activeTab,
  setActiveTab,
  enableTabs,
  mode,
}) => {
  const promoCode = usePromoCodeNotification();

  return (
    <div data-testid={dataTestId} className={cx('pagewrapper', dataTestId)}>
      {heading && (
        <div className={cx('heading-container')}>
          <h1
            data-testid={`${dataTestId}-heading`}
            className={cx('pagewrapper__heading', `${dataTestId}-heading`)}
          >
            {heading}
          </h1>
        </div>
      )}

      {promoCode && (
        <Notification>
          <>
            <Tick />
            <p>
              Promo code &nbsp;
              <b data-notranslate>{promoCode}</b>
              &nbsp; copied
            </p>
          </>
        </Notification>
      )}
      {mode === 'checkout' && (
        <Tabs
          enableTabs={enableTabs}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        />
      )}

      <div
        className={cx('pagewrapper__content', dataTestId)}
        data-testid={`${dataTestId}-wrapper`}
      >
        {children}
      </div>
    </div>
  );
};

PageWrapper.propTypes = {
  heading: PropTypes.string,
  dataTestId: PropTypes.string,
  children: PropTypes.node,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  enableTabs: PropTypes.bool,
  mode: PropTypes.string,
};

PageWrapper.defaultProps = {
  heading: '',
  dataTestId: '',
  children: null,
  activeTab: 'account',
  enableTabs: false,
  setActiveTab: () => {},
  mode: 'checkout',
};

export default PageWrapper;
