import backToLoginClick from './events/backToLoginClick/backToLoginClick';
import checkoutJourney from './events/checkoutJourney/checkoutJourney';
import chooseAnotherEmailClick from './events/chooseAnotherEmailClick/chooseAnotherEmailClick';
import emailLookUp from './events/emailLookup/emailLookUp';
import forgottenPasswordClick from './events/forgottenPasswordClick/forgottenPasswordClick';
import loginOverlayButtonClick from './events/loginOverlayButtonClick/loginOverlayButtonClick';
import myAccountJourney from './events/myAccountJourney/myAccountJourney';
import pageMetaData from './events/pageMetaData/pageMetaData';
import resetPassword from './events/resetPassword/resetPassword';
import signIn from './events/signIn/signIn';
import signUp from './events/signUp/signUp';
import subscribeToNewsletterClick from './events/subscribeToNewsletterClick/subscribeToNewsletterClick';

export default {
  pageMetaData,
  backToLoginClick,
  chooseAnotherEmailClick,
  subscribeToNewsletterClick,
  signIn,
  signUp,
  emailLookUp,
  forgottenPasswordClick,
  resetPassword,
  checkoutJourney,
  myAccountJourney,
  loginOverlayButtonClick,
};
