import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import style from './Notification.module.scss';

const cx = classNames.bind(style);

function Notification({ children }) {
  return (
    <div className={cx('notification')} data-testid="notification">
      {children}
    </div>
  );
}

Notification.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Notification;
