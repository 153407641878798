import { getCurrentActiveFeatureNames } from '@paralleldrive/feature-toggles';
import { FeatureToggles } from '@paralleldrive/react-feature-toggles';
import CookieConsent from '@ri-digital/web-component-cookie-consent';
import '@ri-digital/web-component-cookie-consent/dist/index.css';
import classNames from 'classnames/bind';
import React, { Suspense, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Redirect, Route, Switch } from 'react-router-dom';

import getCookie from '../analytics/helpers/getCookie/getCookie';
import style from './App.module.scss';
import Footer from './components/Footer';
import Header from './components/Header';
import LoadingOverlay from './components/LoadingOverlay';
import LoginOverlay from './components/LoginOverlay';
import CaptchaContext from './helpers/context/CaptchaContext/CaptchaContext';
import {
  useEmailState,
  useHasAccountState,
} from './helpers/hooks/usePersistedState';
import {
  useMode,
  useResetPasswordToken,
  useReturnUrl,
} from './helpers/hooks/useQueryParams';
import useSDKSync from './helpers/hooks/useSDKSync';
import useUniversalVariable from './helpers/hooks/useUniversalVariable';
import { isUSCountryCode } from './helpers/isUSCountryCode';
import { loadRecaptchaScript } from './helpers/recaptcha/recaptcha';
import { httpClient } from './httpClient';
import {
  AccountCreationPage,
  CheckoutSignUpPage,
  EmailValidationPage,
  ForgottenPasswordPage,
  PasswordSignUpPage,
  PasswordValidationPage,
  ResetPasswordPage,
  UnderConstructionPage,
} from './pages';
import { ErrorPage } from './pages/ErrorPage';
import { QueryKeys } from './query-keys';
import {
  accountCreation,
  checkoutSignUp,
  forgottenPassword,
  login,
  notFound,
  passwordSignUp,
  passwordValidation,
  resetPassword,
  underConstruction,
} from './routes';

import initialFeatures from './lib/features.json';

const cx = classNames.bind(style);

const App = () => {
  const mode = useMode();
  const { search } = window.location;
  const [email] = useEmailState();
  const [hasAccount] = useHasAccountState();
  const returnUrl = useReturnUrl();
  const token = useResetPasswordToken();
  const hasLoginCookie = !!getCookie('qb_login');
  const [captchaLoaded, setCaptchaLoaded] = useState(false);
  const [showLoginOverlay, setShowLoginOverlay] = useState(true);
  const activeFeatures = getCurrentActiveFeatureNames({
    initialFeatures,
    req: { query: search },
  });

  const signInJourney = email && hasAccount;
  const signUpJourney = email && !hasAccount;

  const { data, isLoading } = useQuery(QueryKeys.features, () =>
    httpClient.get('/features'),
  );

  const isCookieConsentEnabled = isUSCountryCode()
    ? false
    : data?.features?.find((f) => f.name === 'EnableCookieConsent')?.enabled;

  const isEnableExternalAuthentication = data?.features?.find(
    (f) => f.name === 'EnableExternalAuthentication',
  )?.enabled;

  if (isEnableExternalAuthentication) {
    if (!activeFeatures.includes('EnableExternalAuthentication')) {
      activeFeatures.push('EnableExternalAuthentication');
    }
  }

  useUniversalVariable(mode);
  useEffect(() => {
    if (typeof window.newrelic === 'object') {
      window.newrelic.setCustomAttribute('loginAppVersion', 'v2');
    }
    loadRecaptchaScript(() => {
      setCaptchaLoaded(true);
    });
  }, []);
  useSDKSync();

  return (
    <div className={`${cx('app-container')}`}>
      <Header />
      {!isLoading && isCookieConsentEnabled && <CookieConsent enabled />}
      <div className={`${cx('route-container')}`} data-testid="app-wrapper">
        <FeatureToggles features={activeFeatures}>
          <CaptchaContext.Provider
            value={{ toggles: data?.features, captchaLoaded }}
          >
            <Suspense fallback={<LoadingOverlay loading />}>
              {showLoginOverlay &&
                activeFeatures.includes('EnableExternalAuthentication') && (
                  <LoginOverlay setShow={setShowLoginOverlay} />
                )}

              <Switch>
                {returnUrl && (
                  <Route exact path={login.path}>
                    <EmailValidationPage />
                  </Route>
                )}

                {returnUrl && signInJourney && (
                  <Route path={passwordValidation.path}>
                    <PasswordValidationPage />
                  </Route>
                )}

                {returnUrl && signInJourney && (
                  <Route path={forgottenPassword.path}>
                    <ForgottenPasswordPage />
                  </Route>
                )}

                {returnUrl && (
                  <Route path={underConstruction.path}>
                    <UnderConstructionPage />
                  </Route>
                )}

                {returnUrl && token && (
                  <Route path={resetPassword.path}>
                    <ResetPasswordPage />
                  </Route>
                )}

                {returnUrl && signUpJourney && mode === 'account' && (
                  <Route path={accountCreation.path}>
                    <AccountCreationPage />
                  </Route>
                )}

                {returnUrl && signUpJourney && mode === 'checkout' && (
                  <Route path={checkoutSignUp.path}>
                    <CheckoutSignUpPage />
                  </Route>
                )}

                {returnUrl &&
                  signUpJourney &&
                  mode === 'checkout' &&
                  hasLoginCookie && (
                    <Route path={passwordSignUp.path}>
                      <PasswordSignUpPage />
                    </Route>
                  )}

                <Route path={notFound.path}>
                  <ErrorPage />
                </Route>

                {process.env.NODE_ENV === 'development' ? (
                  <Route path="/connect/authorize/callback">
                    <div>Dev only page</div>
                  </Route>
                ) : null}

                <Route path="*">
                  <Redirect to={notFound.path} />
                </Route>
              </Switch>
            </Suspense>
          </CaptchaContext.Provider>
        </FeatureToggles>
      </div>
      <Footer />
    </div>
  );
};

export default App;
