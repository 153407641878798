import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import analytics from '../../../analytics';
import text from '../../text';
import Header from '../Header';
import style from './LoginOverlay.module.scss';

const cx = classNames.bind(style);

const { loginOverlay } = text;

const LoginOverlay = ({ setShow }) => (
  <div className={cx('container')} data-testid="login-overlay">
    <Header isOverlayHeader />
    <div className={cx('buttons-container')}>
      <button
        type="button"
        className={cx('button')}
        data-testid="continue-with-email"
        onClick={() => {
          setShow(false);
          analytics.loginOverlayButtonClick('riAccountLogin');
        }}
      >
        {loginOverlay.continueWithEmail}
      </button>

      <p className={cx('no-account-text')}>{loginOverlay.noAccountText}</p>

      <div className={cx('line-container')}>
        <span>{loginOverlay.or}</span>
      </div>

      <button
        type="button"
        className={cx('button')}
        data-testid="continue-with-apple"
        onClick={() => {
          analytics.loginOverlayButtonClick('appleLogin');
        }}
      >
        {loginOverlay.continueWithApple}
      </button>
      <button
        type="button"
        className={cx('button')}
        data-testid="continue-with-google"
        onClick={() => {
          analytics.loginOverlayButtonClick('googleLogin');
        }}
      >
        {loginOverlay.continueWithGoogle}
      </button>
    </div>
  </div>
);

LoginOverlay.propTypes = {
  setShow: PropTypes.func.isRequired,
};

export default LoginOverlay;
