import addToDataLayer from '../../helpers/addToDataLayer/addToDataLayer';
import getCookie from '../../helpers/getCookie/getCookie';

const checkoutJourney = () => {
  const currency = getCookie('intl_currency');

  const newData = {
    context: { currency },
    data: [
      {
        step: 'signinselection',
        basket: { items: [] },
      },
    ],
  };
  addToDataLayer('checkout', newData);
};

export default checkoutJourney;
