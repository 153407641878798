import addToDataLayer from '../../helpers/addToDataLayer/addToDataLayer';

const resetPassword = (error) => {
  const data = {
    signin: {
      error: !error ? 0 : 1,
      id: `reset-password`,
      type: `Reset Password`,
    },
  };
  return addToDataLayer('resetPassword', data);
};

export default resetPassword;
